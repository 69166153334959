
import axios, { AxiosError } from "axios";
import { PropType, onMounted, ref, watch } from "vue";
import { defineComponent } from "vue";
import { useRoute, useRouter } from "vue-router";
import { CampUndefinedImgURL } from '@/components/CampModalCropImage'
// import Api from "../../services/Api";
// import s3 from '../../services/S3Service'
import useAlert from "@/composables/Alert";
import { useLoaderStore } from "@/store/LoaderStore"
import { useProductStore } from "@/store/ProductStore"
import { useCompanyStore } from '@/store/CompanyStore';
import { Modal } from "bootstrap";
import { IProductData } from "../ListPage.vue";

export default defineComponent({
  name: "ModalCopyByStore",
  props: {
    openClose: Boolean,
    inforStore: Object,
    dataTable: {
      type: Array as PropType<IProductData[]>,
      default: []
    }
  },
  setup(props, { emit }) {
    /**
     * Variables
     */
    const dialogVisible = ref(false)
    const confirm = ref(false)
    const companies = ref<any>()
    const companiesOrigin = ref<any>()
    const productsCopy = ref<any>()
    const route = useRoute()
    const { id: id_store } = route.params
    const { showTimeAlert } = useAlert()
    const loaderStore = useLoaderStore()
    const router = useRouter()
    const categoryField = ref<any>(null)
    const companyStore = useCompanyStore()
    const companyIdFrom = ref<string | number>("")
    const COMPANY_DESC_TO = ref(companyStore!.company!.fantasy_name)
    const useStore = useProductStore()
    const allTheSelectedItems = ref(false)
    const cancelButtonRef = ref<HTMLButtonElement | null>(null)
    const dataTable = ref<IProductData[]>([])
    const isVisibleModal = ref(false)
    const updateOnlyUrlMedia = ref(false)

    /**
     * Functions
     */
    async function getCompaniesList() {
      try {
        const response = await axios.get('/api/getCompany')
        let companiesResponse = response.data.data
        companiesOrigin.value = response.data.data
        companies.value = companiesResponse.filter(el => el.id != companyStore.id)
      } catch (error) { }
    }

    async function handlePostCopyProduct() {
      loaderStore.open()
      try {
        const response = await axios.post('/api/postCopyProduct', {
          id_company: companyIdFrom.value,
          id_store_to: id_store
        })
        // dialogVisible.value = false
        confirm.value = true
        useStore.setOpenList()
        // router.push(`/produtos`)
        productsCopy.value = response.data.data.map(el => ({ ...el, disabledFraction: !el.createdFraction }))
      } catch (error) {
        if (error instanceof AxiosError) {
          showTimeAlert(error.message, 'error')
        }
      } finally {
        loaderStore.close()
      }
      loaderStore.open()
      try {
        const result: any = await axios.get(`/api/getCategory?id_company=${companyStore.id}`)
        categoryField.value = result.data.data
        openModal('myModalProductCopy')
      } catch (error) {
        if (error instanceof AxiosError)
          showTimeAlert(error.response?.data.message, "error")
        else
          showTimeAlert("Algo deu errado!", "error")
      } finally {
        loaderStore.close()
      }
    }

    async function handleCreatedCopyProduct() {
      loaderStore.open()
      const success = { status: true }
      try {
        const productsConfirm = productsCopy.value.map(el => ({
          ...el,
          category: el.categorySelected
        })).filter(el => el.checked)
        await axios.post('/api/createdCopyProduct', {
          id_company: companyIdFrom.value,
          productsConfirm,
          updateOnlyUrlMedia: updateOnlyUrlMedia.value,
        })
        dialogVisible.value = false
        confirm.value = false
        isVisibleModal.value = false
        cancelButtonRef.value?.click()
        // useStore.setOpenList()
        showTimeAlert('Processamento realizado com sucesso!')
      } catch (error) {
        success.status = false
        await handleCopyingFromSpreadsheet()
        handleAllTheSelectedItems(true)
        if (error instanceof AxiosError)
          showTimeAlert(error.response?.data.message, "error")
        else
          showTimeAlert("Algo deu errado!", "error")
      } finally {
        if(success.status){
          allTheSelectedItems.value = false
          updateOnlyUrlMedia.value = false
        }
        loaderStore.close()
        emit("update:RefreshList", { refresh: true })
      }
    }

    let auxModal;
    const openModal = id => {
      const auxElement = document.querySelector(`#${id}`);
      auxModal = new Modal(auxElement);
      auxModal.show();
    };
    
    function confirmChecked() {
      if(updateOnlyUrlMedia.value){
        return false
      }
      const checked = productsCopy.value.filter(el => el.checked)
      if(checked.length < 1) return true
      else return false
    }

    function handleModalBetweenCompanies() {
      if(dataTable.value.length > 0){
        dataTable.value = []
        productsCopy.value = []
      }
      dialogVisible.value = !dialogVisible.value
    }

    async function handleCopyingFromSpreadsheet() {
      dataTable.value = props.dataTable
      if(props.dataTable.length < 1)
        return showTimeAlert("Planilha vazia", 'error')
      try {
        loaderStore.open()
        dialogVisible.value = false
        confirm.value = true
        const [
          productsRresponse,
          categoriesRresponse,
        ] = await Promise.all([
          axios.post<{ data: IProductData[] }>('/api/checkStatusOfProducts', { data_table: [ ...dataTable.value.map(el =>({ ...el, points_default: +el.points_default })) ] }),
          axios.get<{ data: { categorySelected: string }[] }>(`/api/getCategory?id_company=${companyStore.id}`),
        ])
        const products = productsRresponse?.data.data
        const categories = categoriesRresponse?.data.data
        productsCopy.value = products.map(el => ({ 
        ...el,
        disabledFraction: !el.createdFraction,
        url_media: el.url_media,
        }))
        categoryField.value = categories
        if(!isVisibleModal.value) {
          openModal('myModalProductCopy')
          isVisibleModal.value = true
        }
      } catch (error) {
        if (error instanceof AxiosError)
          showTimeAlert(error.message, 'error')
        else
          showTimeAlert("Algo deu errado", 'error')
      } finally {
        loaderStore.close()
      }
    }

    function handleAllTheSelectedItems(checked:boolean = allTheSelectedItems.value) {
      allTheSelectedItems.value = checked
      productsCopy.value = productsCopy.value.map(el => ({ ...el, checked: allTheSelectedItems.value}))
    }

    function updateAllTheSelectedItems(){
      allTheSelectedItems.value = updateOnlyUrlMedia.value
    }

    /**
     * Life Cycles
     */
    watch(() => props.openClose, () => handleModalBetweenCompanies())

    watch(() => props.dataTable, () => handleCopyingFromSpreadsheet(), { deep: true })

    watch(() => allTheSelectedItems.value, () => handleAllTheSelectedItems())

    watch(() => companyStore.id, () => {
      COMPANY_DESC_TO.value = companyStore!.company!.fantasy_name
      companies.value = companiesOrigin.value.filter(el => el.id != companyStore.id)
      companyIdFrom.value = ""
    })

    onMounted(() => getCompaniesList())

    return {
      dialogVisible,
      companies,
      companyIdFrom,
      COMPANY_DESC_TO,
      handlePostCopyProduct,
      confirm,
      productsCopy,
      categoryField,
      handleCreatedCopyProduct,
      confirmChecked,
      allTheSelectedItems,
      cancelButtonRef,
      isVisibleModal,
      updateOnlyUrlMedia,
      CampUndefinedImgURL,
      updateAllTheSelectedItems
    }
  },
});
